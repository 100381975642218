/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* 
Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { forwardRef, Ref } from "react";

import { InputBaseProps, InputProps, SxProps, Theme } from "@mui/material";

import MDInputRoot from "./MDInputRoot";
import MDBox from "../md-box";

export type MDInputProps = {
  autoFocus?: boolean;
  required?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  type?: "text" | "number" | "date" | "password" | "email" | "time";
  fullWidth?: boolean;
  error?: boolean;
  errorMessage?: string;
  success?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  allowErrors?: boolean;
  dataTestId?: string;
  errorMessageDataTestId?: string;
  variant?: "standard" | "filled" | "outlined";
  inputProps?: InputBaseProps["inputProps"];
  placeholder?: string;
  size?: "small" | "medium" | "large";
  label?: string;
  id?: string;
  shrinkLabel?: boolean;
  defaultValue?: string;
  inputRef?: Ref<HTMLInputElement>;
  sx?: SxProps<Theme>;
  InputProps?: InputProps;
};

const MDInput = forwardRef<HTMLDivElement, MDInputProps>(
  (
    {
      value,
      defaultValue,
      id,
      autoFocus,
      label,
      required,
      dataTestId,
      errorMessageDataTestId,
      variant,
      fullWidth,
      placeholder,
      size,
      onChange,
      type = "text",
      errorMessage = "",
      error = false,
      success = false,
      disabled = false,
      readOnly = false,
      allowErrors = true,
      inputProps = {},
      shrinkLabel,
      inputRef,
      sx,
      InputProps,
    },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (type !== "number") return;
      const { key } = e;
      if ([".", ",", "e", "-", "+"].includes(key)) e.preventDefault();
    };
    return (
      <>
        <MDInputRoot
          id={id}
          defaultValue={defaultValue}
          label={label}
          autoFocus={autoFocus}
          required={required}
          fullWidth={fullWidth}
          type={type}
          value={value}
          onChange={handleChange}
          ownerState={{ error, success, disabled, readOnly }}
          disabled={disabled}
          inputProps={{
            readOnly,
            size,
            "data-testid": dataTestId,
            style: {
              paddingRight: type === "text" ? 35 : 0,
            },
            ...inputProps,
          }}
          variant={variant}
          ref={ref}
          placeholder={placeholder}
          InputLabelProps={{ shrink: shrinkLabel }}
          inputRef={inputRef}
          sx={sx}
          InputProps={InputProps}
          onKeyDown={handleKeyDown}
        />
        {allowErrors && (
          <MDBox minHeight="2.5ch">
            {error && errorMessage && (
              <p
                style={{ color: "red", fontSize: "1ch", paddingTop: "1ch" }}
                data-testid={errorMessageDataTestId}
              >
                {errorMessage}
              </p>
            )}
          </MDBox>
        )}
      </>
    );
  }
);

export default MDInput;
