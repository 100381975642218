import { API_BASE_URL } from "../urls.api";

export const GET_ALL_ROOM_METHOD = "GET";
export const GET_ALL_ROOM_URL = `${API_BASE_URL}/rooms`;

export const GET_BY_OWNER_ROOM_METHOD = "GET";
export const GET_BY_OWNER_ROOM_URL = `${API_BASE_URL}/rooms/owner/id`;

export const GET_ROOM_METHOD = "GET";
export const GET_ROOM_URL = `${API_BASE_URL}/rooms`;

export const CREATE_ROOM_METHOD = "POST";
export const CREATE_ROOM_URL = `${API_BASE_URL}/rooms`;

export const UPDATE_ROOM_METHOD = "PUT";
export const UPDATE_ROOM_URL = `${API_BASE_URL}/rooms`;

export const DELETE_ROOM_METHOD = "DELETE";
export const DELETE_ROOM_URL = `${API_BASE_URL}/rooms/:id`;

export const GET_PAGINATED_ROOMS = `${API_BASE_URL}/rooms`;

export const getRoomUsedLicensesUrl = (userId?: string) =>
  `${API_BASE_URL}/rooms/user/used-licenses${userId ? `?userId=${userId}` : ""}`;

export const getUpdateRoomSettingsUrl = (roomId: string) =>
  `${API_BASE_URL}/rooms/${roomId}/settings`;

export const getByOwnerIdRoomsUrl = (ownerId?: string) =>
  `${GET_BY_OWNER_ROOM_URL}${ownerId ? `?ownerId=${ownerId}` : ""}`;

export const getUpdateRoomByMonitorIdUrl = (monitorId: string) =>
  `${API_BASE_URL}/rooms/monitor/${monitorId}`;

export const swapMonitorUrl = (roomId: string, monitorId: string) =>
  `${API_BASE_URL}/rooms/${roomId}/swap-monitors?monitorId=${monitorId}`;
