import React, { ChangeEvent, useEffect, useRef } from "react";

import { SxProps } from "@mui/material";
import TextField from "@mui/material/TextField";

interface TextAreaComponent {
  label?: string;
  rows?: number;
  onChange: (text: string) => void;
  variant?: "outlined" | "filled" | "standard";
  value: string;
  fullWidth?: boolean;
  sx?: SxProps;
  placeholder?: string;
}

export const TextAreaComponent: React.FC<TextAreaComponent> = ({
  label,
  rows = 4,
  onChange,
  variant = "outlined",
  value,
  fullWidth = true,
  sx,
  placeholder,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <TextField
      multiline
      inputRef={textAreaRef}
      rows={rows}
      variant={variant}
      label={label}
      onChange={handleChange}
      value={value}
      fullWidth={fullWidth}
      sx={sx}
      inputProps={{
        maxLength: 5000,
      }}
      placeholder={placeholder}
    />
  );
};
