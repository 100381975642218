import React from "react";
import { StyledFieldLabel } from "./input-label.styles";

interface InputLabelProps {
  text: string;
  required?: boolean;
}

export const InputLabelComponent: React.FC<InputLabelProps> = ({ text, required }) => {
  return <StyledFieldLabel required={required}>{text}</StyledFieldLabel>;
};
