import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { OptionComponent } from "./components";
import { useSelectSearchPaginated } from "./select-search-paginated.hook";
import { SelectSearchPaginatedComponentProps } from "./select-search-paginated.model";
import { useInternationalization } from "../../contexts";

export const SelectSearchPaginatedComponent: React.FC<SelectSearchPaginatedComponentProps> = ({
  name,
  menuItems,
  hasMore,
  loading,
  onChange,
  searchFunction,
  limitFunction,
  disabled,
  readOnly,
  fullWidth,
  noOptionsText,
  placeholder,
  value,
  sx,
}) => {
  const { handleLoadMore, handleOnChange, handleSearch, search } = useSelectSearchPaginated(
    searchFunction,
    limitFunction,
    onChange
  );
  const { t } = useInternationalization("components.select-search-users");

  const options = [
    ...menuItems,
    ...(value && menuItems.findIndex((item) => item.key === value.key) === -1 ? [value] : []),
    ...(hasMore ? [{ key: "load-more", value: t("load-more") }] : []),
  ];

  return (
    <Autocomplete
      value={value}
      noOptionsText={noOptionsText}
      color="dark"
      id={"select-" + name}
      filterOptions={(x) => (value ? [value, ...x] : x)}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disabled={disabled}
      readOnly={readOnly}
      autoHighlight
      fullWidth={fullWidth}
      inputValue={search}
      onChange={handleOnChange}
      options={options}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      renderOption={(props, option) => {
        return (
          <OptionComponent
            key={option.key}
            liProps={props}
            option={option}
            loading={loading}
            handleLoadMore={handleLoadMore}
            loadMoreText={t("load-more")}
            loadingText={t("loading")}
          />
        );
      }}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.value)}
      style={{ width: "100%" }}
      sx={sx}
      onInputChange={(event, value) => handleSearch(event, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: "4.5em",
            },
          }}
          placeholder={placeholder}
          size="small"
        />
      )}
    />
  );
};
