import { API_BASE_URL } from "../urls.api";
import { Resource } from "@/pages/rooms/details/components/resources/types";

export const createResource = async (body: Omit<Resource, "_id">): Promise<{ data: Resource }> =>
  fetch(`${API_BASE_URL}/resources/room/${body.linkId}`, {
    method: "post",
    credentials: "include",
    body: JSON.stringify({ ...body }),
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.json());

export const createResourceToNode = async (
  body: Omit<Resource, "_id">
): Promise<{ data: Resource }> =>
  fetch(`${API_BASE_URL}/resources`, {
    method: "post",
    credentials: "include",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.json());

export const updateResource = async (
  resource: Partial<Resource>,
  resourceId: string
): Promise<{ data: Resource }> => {
  try {
    const response = await fetch(`${API_BASE_URL}/resources/${resourceId}/content`, {
      method: "PATCH",
      credentials: "include",
      body: JSON.stringify(resource),
      headers: { "Content-Type": "application/json" },
    });

    return response.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteResource = async (ownerId: string, resourceId: string): Promise<Response> =>
  fetch(`${API_BASE_URL}/resources/${resourceId}/owner/${ownerId}`, {
    method: "DELETE",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });

export const getResourcesByRoomAndModule = async (
  roomId: string,
  ownerId: string,
  module?: string
): Promise<{ data: Resource[] }> => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/resources/owner/${ownerId}/room/${roomId}${module ? `?module=${module}` : ""}`,
      {
        ...(module && { search: `?module=${module}` }),
        method: "get",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return { data: [] };
  }
};

export const getProtocolsByNode = async (
  nodeId: string,
  ownerId: string,
  module?: string
): Promise<{ data: Resource[] }> => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/resources/owner/${ownerId}/node/${nodeId}${module ? `?moduleType=${module}` : ""}`,
      {
        ...(module && { search: `?moduleType=${module}` }),
        method: "get",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return { data: [] };
  }
};

export const getGraph = async (ownerId: string, roomId: string) => {
  const response = await fetch(`${API_BASE_URL}/nodes/path/${ownerId}/room/${roomId}`, {
    method: "get",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const { data } = await response.json();

  return data;
};

export const getProtocolsByNodes = async (
  roomId: string,
  ownerId: string,
  module?: string
): Promise<{ data: Resource[] }> => {
  try {
    const nodeGraph = await getGraph(ownerId, roomId);

    const protocolsPromises = nodeGraph.map((node: { _id: string }) =>
      getProtocolsByNode(node._id, ownerId, module ?? "")
    );
    const protocolsData = await Promise.all(protocolsPromises);
    const protocols = protocolsData.map((protocolResponse) => protocolResponse.data).flat(1);
    console.log(protocols);

    return {
      data: protocols.map((protocol) => ({
        ...protocol,
        nodeName:
          nodeGraph.find((node: { _id: string }) => node._id === protocol.linkId)?.content?.name ||
          "",
      })),
    };
  } catch (error) {
    console.error("Error fetching protocols by nodes:", error);
    return { data: [] };
  }
};

export const moveResourceToNode = async (
  resourceId: string,
  nodeId: string
): Promise<{ data: Resource & { nodeId: string } }> => {
  const response = await fetch(`${API_BASE_URL}/resources/${resourceId}/link/${nodeId}`, {
    method: "PATCH",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const { data } = await response.json();
  return {
    data: {
      ...data,
      nodeId,
    },
  };
};

export const deleteAllResourcesByNode = async (
  nodeId: string,
  ownerId: string
): Promise<Response> =>
  fetch(`${API_BASE_URL}/resources/owner/${ownerId}/nodeId/${nodeId}`, {
    method: "DELETE",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.json());

export const filesAdmin = async (
  ownerId: string,
  resourceId: string,
  field: string,
  files: Array<{ filename: string; file?: File }>
) => {
  const formData = new FormData();
  formData.append("field", field);

  const filenames = files.filter((f) => !f.file).map((f) => f.filename);
  formData.append("filenames", filenames.join(","));

  files.forEach((fileData) => {
    if (fileData.file) {
      formData.append("files", fileData.file);
    }
  });

  const response = await fetch(`${API_BASE_URL}/resources/${resourceId}/owner/${ownerId}/upload`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });
  const { data } = await response.json();
  return data;
};

export const getFileUrl = async (fileName: string): Promise<string> => {
  const response = await fetch(`${API_BASE_URL}/resources/file?fileName=${fileName}`, {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  return data.data;
};
