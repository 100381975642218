import { styled } from "@mui/material";
import MDBox from "../md-box";

export const ContainerAccordionTitle = styled(MDBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: theme.spacing(2.375),
}));

export const ContainerBoxAccordionTitle = styled(MDBox)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: theme.spacing(2.375),
  lineHeight: 0,
  cursor: "pointer",
}));

export const BoxIconAccordionTitle = styled(MDBox)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.gray[7],
}));
