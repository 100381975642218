import { Icon } from "@mui/material";
import React, { useState } from "react";
import MDBox from "../md-box";
import {
  BoxIconAccordionTitle,
  ContainerAccordionTitle,
  ContainerBoxAccordionTitle,
} from "./accordion-title.styles";

interface AccordionProps {
  title: React.ReactNode;
  children: React.ReactNode;
  initialValue?: boolean;
  gap?: number;
}

export const AccordionTitleComponent: React.FC<AccordionProps> = ({
  title,
  initialValue = true,
  children,
  gap = 2,
}) => {
  const [showAccordion, setShowAccordion] = useState<boolean>(initialValue);

  const handleSetShowAccordion = () => setShowAccordion(!showAccordion);

  return (
    <MDBox display="flex" flexDirection="column" sx={{ width: "100%" }} gap={gap}>
      <ContainerAccordionTitle>
        <ContainerBoxAccordionTitle onClick={handleSetShowAccordion}>
          {title}
          {showAccordion ? (
            <BoxIconAccordionTitle>
              <Icon color="inherit" fontSize="medium">
                keyboard_arrow_up
              </Icon>
            </BoxIconAccordionTitle>
          ) : (
            <BoxIconAccordionTitle>
              <Icon color="inherit" fontSize="medium">
                keyboard_arrow_down
              </Icon>
            </BoxIconAccordionTitle>
          )}
        </ContainerBoxAccordionTitle>
      </ContainerAccordionTitle>
      {showAccordion && children}
    </MDBox>
  );
};
