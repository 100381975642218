import { PaginatedRooms } from "@/models/room";
import { PaginatedRoom } from "@/models/room/room.list.model";
import { PaginatedRoomDTO, PaginatedRoomsDTO } from "../dtos";

export const mapPaginatedRoomsDTOtoPaginatedRooms = (
  paginatedRooms: PaginatedRoomsDTO["data"]
): PaginatedRooms => {
  return {
    rooms: paginatedRooms.rooms.map(mapPaginatedRoomDTOtoRoom),
    totalPages: paginatedRooms.totalPages,
    totalRooms: paginatedRooms.totalRooms,
  };
};

const mapPaginatedRoomDTOtoRoom = (room: PaginatedRoomDTO): PaginatedRoom => {
  return {
    _id: room._id,
    name: room.name,
    monitorId: room.monitorId,
    monitor: Boolean(room.monitor),
    nightAgent: room.nightAgent,
    deviceFirm: room.deviceFirm,
    batteryCapacity: room.batteryCapacity,
    address: room.address,
    username: room.username,
    smokingAlertThreshold: room.smokingAlertThreshold,
    crowdingAlertTreshold: room.crowdingAlertTreshold,
    noiseSettings: room.noiseSettings,
    data: {
      level: room.data.level,
      value: room.data.value,
      updatedAt: room.data.updatedAt,
      wifi: room.data.wifi,
      quality: room.data.quality,
      localip: room.data.localip,
      batteryLevel: room.data.batteryLevel,
      batteryMode: room.data.batteryMode,
    },
  };
};
