import { Icon } from "@mui/material";
import React from "react";

interface InfoIconBtnProps {
  handleClick: () => void;
}

export const InfoIconBtnComponent = ({ handleClick }: InfoIconBtnProps) => {
  return (
    <Icon
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleClick();
      }}
      sx={{
        color: "inherit",
        fontSize: 20,
        cursor: "pointer",
      }}
      color="inherit"
    >
      info
    </Icon>
  );
};
