import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import React from "react";

interface CollapseButtonProps {
  isMobile: boolean;
  collapsed: boolean;
  onToggle: () => void;
}

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  isMobile,
  collapsed,
  onToggle,
}) => {
  const theme = useTheme();
  if (!isMobile) return null;

  return (
    <IconButton
      onClick={onToggle}
      sx={{
        color: theme.palette.gray[7],
      }}
    >
      {collapsed ? <KeyboardArrowDown fontSize="medium" /> : <KeyboardArrowUp fontSize="medium" />}
    </IconButton>
  );
};
