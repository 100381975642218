import React from "react";
import MDBox from "../md-box";
import { StyledOutlinedInput, StyledOutlinedInputLabel } from "./outlined-input.styles";

type OutlinedInputProps<T> = {
  type?: "text" | "number";
  placeholder?: string;
  label?: string;
  value?: T extends string ? string : number;
  onChange?: (value: T) => void;
  helperText?: string;
  slotProps?: {
    htmlInput?: {
      maxLength?: number;
      readOnly?: boolean;
      min?: number;
      max?: number;
    };
  };
  success?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  allowError?: boolean;
};

export const OutlinedInput = <T extends string | number>({
  type = "text",
  label,
  placeholder,
  value,
  onChange,
  helperText,
  slotProps,
  success,
  error,
  fullWidth,
  required,
  allowError = true,
}: OutlinedInputProps<T>) => {
  return (
    <MDBox>
      {label && <StyledOutlinedInputLabel required={required}>{label}</StyledOutlinedInputLabel>}
      <StyledOutlinedInput
        type={type}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange?.(e.target.value as T)}
        helperText={helperText}
        slotProps={slotProps}
        fullWidth={fullWidth}
        ownerState={{
          error,
          success,
          allowError,
        }}
      />
    </MDBox>
  );
};
