import { jsonFetch } from "@/lib/helpers";
import { API_METHODS } from "../shared";
import { REQUEST_OTA_UPDATE_DEVICE_FIRMWARE } from "./api-url.constants";

export const requestOtaUpdateDeviceFirmware = async (body: {
  monitorId: string;
}): Promise<boolean> => {
  const result = await jsonFetch<{ data: { requested: boolean } }>(
    REQUEST_OTA_UPDATE_DEVICE_FIRMWARE,
    {
      method: API_METHODS.POST,
      body: JSON.stringify(body),
    }
  );

  return result.data.requested;
};
